import React from 'react';

function WorkersData({informationHours, user, total, toggle, someData, isShowing, userAndProject}) {
  return (
    <div>
      <div className="">
        {informationHours / 3600 > 1 && (
          <img
            className="workers"
            alt=""
            src={require(`./img/icons/${user}.png`)}
            style={{
              position: 'absolute',
              bottom: '20%',
              left: `${(informationHours / 3600 / total) * 100}%`,
            }}
          />
        )}
        <div
          onClick={toggle}
          className="popup"
          style={{
            position: 'absolute',
            bottom: '20%',
            left: `${(informationHours / 3600 / total) * 100}%`,
          }}
        >
          {isShowing ? (
            <span className="popuptext" id="myPopup">
              <div>Name: {someData && someData.hsUser.hs_id === user && <p>{someData.hsUser.hs_name}</p>}</div>
              <div>Hours: {Math.round(informationHours / 3600)}</div>
              <div>
                Currently working on project num:
                {userAndProject &&
                  Object.entries(userAndProject).map(
                    ([project_user, project_id], i) => parseInt(project_user) === user && <p>{project_id}</p>
                  )}
                Status: {someData && someData.hsUser.hs_id === user && <p>{someData.hsUser.hs_status}</p>}
              </div>
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkersData;

import React, { createContext, useEffect, useState } from 'react';
export const Context = createContext({});
const GqlClient = require('graphql-request').GraphQLClient;
const endpoint = 'https://api.8base.com/ckct5zixx01cu07midt3qd99g';
const token = '394e0a23-91f0-4fe6-8830-1a169d590bc4';

export const Provider = props => {
  const lastMonthResults = 0;
  const [MonthState, setMonthState] = useState(lastMonthResults);

  const [data, setSomeData] = useState('');
  const client = new GqlClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const fd = () => {
    var d = new Date(firstDay),
      month = '' + (d.getMonth() + 1 - `${MonthState}`),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const ld = () => {
    var d = new Date(lastDay),
      month = '' + (d.getMonth() + 1 - `${MonthState}`),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  console.log(ld());

  const boardsQuery = `
  query{
    hsActivitiesList(filter: {hs_date: {gt: "${fd()}", lt: "${ld()}"}}) {
      items {
        hs_user_id
        hs_tracked
      }
    }
  }
`;

  useEffect(() => {
    const exec = async () => {
      const resp = await client.request(boardsQuery);
      setSomeData(resp);
    };
    exec();
  }, []);

  const userAndTotal =
    data.hsActivitiesList &&
    data.hsActivitiesList.items.reduce((a, row) => {
      const { hs_user_id, hs_tracked } = row;
      if (hs_user_id in a) a[hs_user_id] += hs_tracked;
      else a[hs_user_id] = hs_tracked;
      return a;
    }, {});

  const uploudingContext = {
    userAndTotal,
    lastMonthResults,
    MonthState,
    setMonthState,
  };

  return <Context.Provider value={uploudingContext}>{props.children}</Context.Provider>;
};

export const TaskListContextProvider = Provider;
export const uploudingContext = Context;

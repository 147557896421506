import React, { useState, useEffect } from 'react';
import './profil.css';
import WorkersData from './WorkersData';
const GqlClient = require('graphql-request').GraphQLClient;
const endpoint = 'https://api.8base.com/ckct5zixx01cu07midt3qd99g';
const token = '394e0a23-91f0-4fe6-8830-1a169d590bc4';

function Workers({ informationHours, user}) {
  const [total] = useState(150);

  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  const [someData, setSomeData] = useState('');

  const client = new GqlClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const boardsQuery = `
query{
  hsUser(hs_id: ${user}) {
    hs_name
    hs_status
    hs_id
  }
  hsActivitiesList {
    items {
      hs_user_id
      hs_project_id
    }
  }
}
`;

  useEffect(() => {
    const exec = async () => {
      const resp = await client.request(boardsQuery);
      setSomeData(resp);
    };
    exec();
  }, []);

  const userAndProject =
    someData.hsActivitiesList &&
    someData.hsActivitiesList.items.reduce((a, row) => {
      const { hs_user_id, hs_project_id } = row;
      if (hs_user_id in a) a[hs_user_id].add(hs_project_id);
      else a[hs_user_id] = new Set();
      return a;
    }, {});

  return (
    <div>
      <WorkersData
        informationHours={informationHours}
        user={user}
        total={total}
        toggle={toggle}
        someData={someData}
        isShowing={isShowing}
        userAndProject={userAndProject}
      />
    </div>
  );
}

export default Workers;

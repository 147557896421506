import gql from 'graphql-tag';
/**
 * Query the current users details.
 */
export const CURRENT_USER_QUERY = gql`


  query MyQuery {
    hsActivitiesList {
      items {
        hs_id
        hs_keyboard
        hs_manual
        hs_mouse
        hs_overall
        hs_project_id
        hs_date
        hs_task_id
        hs_tracked
        hs_user_id
        id
      }
    }
  }
`;
/**
 * Sign up a new user mutation.
 */
export const USER_SIGN_UP_MUTATION = gql`
  mutation UserSignUp($user: UserCreateInput!, $authProfileId: ID) {
    userSignUpWithToken(user: $user, authProfileId: $authProfileId) {
      id
      email
    }
  }
`;

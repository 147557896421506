import React, { useContext, useState } from 'react';
import { uploudingContext } from '../../Context/TaskListContext';
import './profil.css';
import Workers from './Workers';

function Profile(props) {
  const { userAndTotal, MonthState, setMonthState } = useContext(uploudingContext);
 

  return (
    <div className="App">
      <div className="main-background">
        <div className="dropdown">
          <i class="fa fa-arrow-left" onClick={() => setMonthState(MonthState + 1)}></i>
          <i class="fa fa-arrow-right"onClick={() => setMonthState(MonthState - 1)}></i>
        </div>
        <img className="main-background" src={require('./img/classic1.png')} alt="main-background" />
        {userAndTotal &&
          Object.entries(userAndTotal).map(([user, hours], i) => (
            <Workers informationHours={hours} user={parseInt(user)} />
          ))}
      </div>
    </div>
  );
}

export { Profile };
